import dayjs from 'dayjs';
import { ICommonFilter } from '../../commonTypes';

export const searchOptions = [{ value: 'amount', label: 'Amount' }];

export const defaultFilter: ICommonFilter = {
	start: dayjs().subtract(30, 'day'),
	end: dayjs(),
	searchOptionType: searchOptions[0].value
};
