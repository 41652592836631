import { Button, Tag } from 'antd';

export default function d2rPromoterStockColumns(setIsCatalogueModalOpen: any, setSelectedCatalogues: any) {
	const showModal = (catalogues: any[]) => {
		setSelectedCatalogues(catalogues);
		setIsCatalogueModalOpen(true);
	};
	return [
		{
			title: 'Created At',
			dataIndex: 'createdAt',
			render: (createdAt: string, record: any) => {
				const isClosed = record.stockType === 1;
				return (
					<div className="text-center">
						{new Date(createdAt).toLocaleString('en-IN', {
							day: '2-digit',
							month: 'long',
							year: 'numeric',
							hour: '2-digit',
							minute: '2-digit'
						})}
						<div className="mt-2">{isClosed ? <Tag color="blue">Closing Count</Tag> : <Tag>Opening Count</Tag>}</div>
					</div>
				);
			},
			width: 170
		},
		{
			title: 'Promoter',
			dataIndex: 'promoter',
			render: (promoter: any) => <div className="text-center">{promoter ? promoter?.fullname : '-'}</div>
		},
		{
			title: 'Company',
			dataIndex: 'companyName',
			render: (companyName: string) => <div className="text-center">{companyName}</div>,
			width: 140
		},
		{
			title: 'Retailer',
			dataIndex: 'retailer',
			render: (retailer: any) => (
				<div className="text-center">{retailer ? `${retailer?.firstName + ' ' + retailer?.lastName} ` : '-'}</div>
			),
			width: 150
		},
		{
			title: 'Items',
			dataIndex: 'catalogues',
			render: (catalogues: any) => (
				<Button type="link" onClick={() => showModal(catalogues)}>
					open
				</Button>
			)
		}
	];
}
