import { Button, Col, Form, Input, Modal, notification, Select, Tag } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultPageConfig } from '../../../../constants';
import { apiHms } from '../../../../shared/api';
import { TopFilterComponent } from '../../../../shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../../../../shared/components/CustomTablePagination';
import errorHandler from '../../../../shared/utils/errorHandler';
import { d2rPromoterColumns, defaultFilter, promoterFilters } from './utils';
import { cleanObject } from 'helper';
import { IPageConfiguration } from '../../../../app/AppAdmin/commonTypes';
import { PromoterInfoSlider } from '../../../../shared/components/PromoterInfoSlider/PromoterInfoSlider';
import SelectRetailerDropdown from 'shared/components/SelectRetailerDropdown/SelectRetailerDropdown';
import { HMSUSERTYPE, ICommonFilterSearch } from '../utils';

export const Promoter = () => {
	const [form] = Form.useForm();
	const [promoterData, setPromoterData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageConfig, setPageConfig] = useState<IPageConfiguration>(defaultPageConfig);
	const [isCreatePromoterModalVisible, setIsCreatePromoterModalVisible] = useState(false);
	const [filter, setFilter] = useState<ICommonFilterSearch>(defaultFilter);
	const [promoterCount, setPromoterCount] = useState(0);
	const setPageConfigDefault = () => {
		setPageConfig((v) => ({ ...v, ...defaultPageConfig }));
	};
	const [isPromoterDrawerVisible, setIsPromoterDrawerVisible] = useState<boolean>(false);
	const [promoterId, setPromoterId] = useState<string | number>('');
	const [reloadPromoterList, setReloadPromoterList] = useState<number>(0);
	const [retailerList, setRetailerList] = useState<any>([]);
	const [retailerfilter, setRetailerFilter] = useState('storeName');
	const [selectedRetailer, setSelectedRetailer] = useState<any>([]);

	const topFilterConfig = useMemo(
		() =>
			promoterFilters({
				setIsCreatePromoterModalVisible,
				setFilter,
				filter,
				setPageConfigDefault
			}),
		[filter]
	);
	const columnsConfig = useMemo(() => d2rPromoterColumns(setIsPromoterDrawerVisible, setPromoterId), []);

	const getPromoterData = useCallback(async () => {
		const { data } = await apiHms.post('/admin/promoter/list', {
			where: cleanObject({ ...filter }, ['', null, undefined]),
			pagination: {
				records: pageConfig.pageSize,
				pageNumber: pageConfig.current
			},
			sortBy: {
				order: pageConfig.sortField,
				by: pageConfig.sortOrder
			},
			usertype: HMSUSERTYPE.PROMOTER
		});

		if (data?.status) {
			setPromoterData(data?.users);
			setPromoterCount(data?.count);
		}
	}, [filter, reloadPromoterList, pageConfig]);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			await getPromoterData();
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}, [getPromoterData]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const onSubmitCreatePromoter = async (values: any) => {
		try {
			const { data } = await apiHms.post('/admin/promoter', { ...values, user: HMSUSERTYPE.PROMOTER });
			setIsCreatePromoterModalVisible(false);
			fetchData();

			if (data.status) {
				notification.success({
					message: 'Promoter Created Successfully',
					description: data?.message,
					placement: 'topRight'
				});
			}
			setRetailerList([]);
			setSelectedRetailer([]);
		} catch (err: any) {
			const errData = err?.response?.data;
			const errMsg = errData?.message || errData?.responseMessage || errData?.message || 'error creating promoter';

			notification.error({
				message: errMsg,
				placement: 'topRight'
			});
		}
	};

	const handleTableChange = (pagination: IPageConfiguration, filters: any, sorter: any) => {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'createdAt',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	};

	const handleFilterSelect = (value: any) => {
		setRetailerFilter(value);
	};

	const handleSelect = (value: any) => {
		form.setFieldsValue({ retailers: value });
		const retailer = retailerList.find((r: any) => r.id === value);
		if (retailer && !selectedRetailer.some((r: any) => r.id === retailer.id)) {
			setSelectedRetailer((prev: any) => [...prev, retailer]);
		}
	};

	const handleRemoveRetailer = (retailerId: any) => {
		setSelectedRetailer((prev: any) => prev.filter((r: any) => r.id !== retailerId));
	};

	const handleModalClose = () => {
		setSelectedRetailer([]);
		setIsCreatePromoterModalVisible(false);
	};

	return (
		<>
			<Col sm={24} lg={24} span={24} className="main">
				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columnsConfig}
							total={promoterCount}
							data={promoterData}
							loading={loading}
							shouldRowSelection={false}
							selectedItem={undefined}
							selectedRowKeys={undefined}
							setSelectedRowKeys={undefined}
							setSelectedItem={undefined}
							onChangePage={(current: any, pageSize: any) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							onChange={handleTableChange}
							{...pageConfig}
						/>
					</div>
					{isPromoterDrawerVisible && (
						<PromoterInfoSlider
							isPromoterDrawerVisible={isPromoterDrawerVisible}
							promoterData={promoterData.filter((R: any) => R.hmsUserReferenceId === promoterId)}
							setIsPromoterDrawerVisible={setIsPromoterDrawerVisible}
							setReloadPromoterList={setReloadPromoterList}
							promoterId={promoterId}
							retailerList={retailerList}
							setRetailersList={setRetailerList}
						/>
					)}
				</div>
			</Col>
			<Modal
				title={'Create Promoter'}
				visible={isCreatePromoterModalVisible}
				onCancel={handleModalClose}
				footer={null}
				centered
				destroyOnClose
			>
				<Form size="small" layout="vertical" onFinish={onSubmitCreatePromoter}>
					<Form.Item
						name={'name'}
						label={'Name'}
						style={{ width: '65%' }}
						rules={[{ required: true, message: 'Please enter name' }]}
					>
						<Input size="middle" placeholder={`Enter Name`} />
					</Form.Item>

					<Form.Item
						name={'phone'}
						label={'Phone'}
						style={{ width: '65%' }}
						rules={[{ required: true, message: 'Please enter phone' }]}
					>
						<Input size="middle" placeholder={`Enter Phone`} />
					</Form.Item>

					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<Form.Item label="Store Address">
							<div>
								<Select style={{ width: '130px' }} placeholder="storeName" onSelect={handleFilterSelect}>
									{['storeName', 'phone', 'gstin'].map((i) => {
										return (
											<Select.Option value={i} key={i}>
												{i}
											</Select.Option>
										);
									})}
								</Select>
							</div>
						</Form.Item>
						<Form.Item
							name="retailers"
							rules={[{ required: true, message: 'Please select store address' }]}
							style={{ width: '340px', marginTop: 30, marginLeft: 4 }}
						>
							<SelectRetailerDropdown
								handleSelect={handleSelect}
								retailerList={retailerList}
								setRetailers={setRetailerList}
								filter={retailerfilter}
								value={selectedRetailer}
							/>
						</Form.Item>
					</div>

					<div style={{ marginTop: '20px' }}>
						{selectedRetailer.map((retailer: any) => {
							return (
								<Tag
									key={retailer.id}
									closable
									onClose={() => handleRemoveRetailer(retailer.id)}
									style={{ marginBottom: '5px' }}
								>
									{retailer?.firstName ?? ''} {retailer?.lastName ?? ''} {retailer?.phone ?? ''} {retailer?.GSTIN ?? ''}
								</Tag>
							);
						})}
					</div>

					<Form.Item>
						<Button
							type="primary"
							size="middle"
							block
							htmlType="submit"
							loading={loading}
							style={{ top: '20px', width: '10rem' }}
						>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};
