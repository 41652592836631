import { Col, Row } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getPayments, initiatePayment } from '../../../../shared/api/bms';
import { TopFilterComponent } from '../../../../shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../../../../shared/components/CustomTablePagination';
import errorHandler from '../../../../shared/utils/errorHandler';
import useColumns from './columns';
import { defaultFilter } from './constants';
import { IFilter } from './types';
import { topFilterUtil } from './utils';
import { IPageConfiguration } from '../../commonTypes';
import { PaymentModal } from 'shared/components/PaymentRequestModal/PaymentModal';
import { defaultPageConfig } from '../../../../constants';

export default function Payments() {
	const [data, setData] = useState([]);
	const [currentCount, setCurrentCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<IFilter>(defaultFilter);
	const [pageConfig, setPageConfig] = useState<IPageConfiguration>(defaultPageConfig);
	const columns = useColumns();
	const [isPaymentLoading, setIsPaymentLoading] = useState(false);
	const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);

	const setPageConfigDefault = useCallback(() => {
		setPageConfig(defaultPageConfig);
	}, []);

	const topFilterConfig = useMemo(
		() => topFilterUtil({ filter, setFilter, setPageConfigDefault, setModalVisible: setIsPaymentModalVisible }),
		[filter, setFilter, setPageConfigDefault]
	);

	useEffect(() => {
		async function getAllPayments({ pageConfiguration, filters }: any) {
			try {
				setLoading(true);
				const response = await getPayments({ pageConfiguration, filters });
				if (response?.data.status) {
					setData(response.data.data);
					setCurrentCount(response.data.currentCount);
				} else {
					throw new Error(response?.data.message || 'Could not get payments');
				}
			} catch (error: unknown) {
				errorHandler(error);
			} finally {
				setLoading(false);
			}
		}

		getAllPayments({ pageConfiguration: pageConfig, filters: filter });
	}, [filter, pageConfig]);

	const paymentInitiate = async (values: any) => {
		try {
			setIsPaymentLoading(true);
			const response = await initiatePayment({ amount: values.amount, mobile: values.mobile, message: values.message });

			if (response?.data.success && response.data?.url) {
				alert(`Subdomain not whitelisted by PhonePe!! (${response.data.url})`);
			} else {
				throw new Error(response?.data.message || 'Could not get payments');
			}
		} catch (error: unknown) {
			errorHandler(error);
		} finally {
			setIsPaymentLoading(false);
		}
	};

	return (
		<Row className="h-100 p-0">
			<Col span={24} className="main">
				<PaymentModal
					title="Initiate Payment"
					visible={isPaymentModalVisible}
					onCancel={() => {
						setIsPaymentModalVisible(false);
					}}
					footer={[]}
					width={500}
					onFinish={(values: any) => paymentInitiate(values)}
					instructionMessage="Initiate Phonepe Collection Request"
					loading={isPaymentLoading}
				/>

				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columns}
							data={data}
							onChangePage={(current: any, pageSize: any) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							current={Number(pageConfig.current)}
							total={currentCount}
							loading={loading}
							shouldRowSelection={false}
							selectedItem={undefined}
							catalog={undefined}
							selectedRowKeys={undefined}
							setSelectedRowKeys={undefined}
							setSelectedItem={undefined}
							pageSize={Number(pageConfig.pageSize)}
							sortField={pageConfig.sortField}
							sortOrder={pageConfig.sortField}
						/>
					</div>
				</div>
			</Col>
		</Row>
	);
}
