import { Badge, Button, Col, Row, Tooltip } from 'antd';
import { DebouncedCompactSearch } from '../../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import export_svg from '../../../../shared/svgs/export_svg';
import { commonTopFilterRow } from '../../../../utils/filters';
import { ICommonFilter } from '../../commonTypes';
import { companyType } from '../D2r';
import dayjs from 'dayjs';
import { SimpleSearch } from 'shared/components/SimpleSearch/SimpleSearch';

export const searchOptions = [
	{ value: 'retailer', label: 'Retailer Name' },
	{ value: 'company', label: 'Company Name' }
];

export const combinedSearchOption = [{ value: 'promoter', label: 'Promoter Name' }];

export const defaultFilter: ICommonFilter = {
	start: dayjs().subtract(30, 'day'),
	end: dayjs(),
	searchOptionType: searchOptions[0].value
};

export interface IFilter extends ICommonFilter {
	status?: number;
	company?: companyType;
}

export function topFilterUtil({ filter, setFilter, setPageConfigDefault, selectedRowKeys, onCSVDownload }: any): any {
	const setDate = (startDate: string, endDate: string) => {
		setPageConfigDefault();
		setFilter((prevFilter: IFilter) => ({ ...prevFilter, start: startDate, end: endDate }));
	};
	const topFilterRow = commonTopFilterRow(filter.start, filter.end);

	return {
		row1: [
			{
				...topFilterRow,
				onChange: (dateStrings: [string, string]) => setDate(...dateStrings)
			}
		],
		row1children: [
			<div key={1}>
				<DebouncedCompactSearch
					defaultSearchOptionType={filter.searchOptionType}
					searchOptions={searchOptions}
					setFilter={setFilter}
					setDefault={setPageConfigDefault}
					selectStyle={{ minWidth: '150px' }}
				>
					<span>Search By:</span>
				</DebouncedCompactSearch>
			</div>,
			<div key={1}>
				{selectedRowKeys.length !== 0 && (
					<Badge size="default" count={`${selectedRowKeys.length} selected`} style={{ backgroundColor: '#6c757d' }} />
				)}
			</div>,

			<div key={1} style={{ marginLeft: 20 }}>
				<SimpleSearch
					filters={filter}
					defaultSearchOptionType={combinedSearchOption[0].value}
					searchOptions={combinedSearchOption}
					setFilter={setFilter}
					setDefault={setPageConfigDefault}
					selectStyle={{ minWidth: '150px' }}
				>
					<span>Search By Promoter:</span>
				</SimpleSearch>
			</div>
		],

		row2: [
			<Row key={2}>
				<Col>
					<Tooltip placement="left" title={'Export details for promoter Stock'}>
						<Button style={{ marginTop: '1.5rem', marginRight: '0.5rem' }} onClick={onCSVDownload}>
							<span>{export_svg}</span>
							<span style={{ marginLeft: '.4rem', fontSize: '0.85rem' }}>Export</span>
						</Button>
					</Tooltip>
				</Col>
			</Row>
		]
	};
}
