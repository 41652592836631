import { apiOms } from '.';

export enum EUploadFileKey {
	RTO_POD = 'rto_pod',
	DTO_POD = 'dto_pod',
	PRODUCT_IMG = 'product_img'
}

export const getS3Link = (key: EUploadFileKey, fileType: string, fileName: string, metadata: any) => {
	return apiOms.post('/order/presigned', { key, fileType, fileName, metadata });
};

export enum D2REndpointType {
	PROMOTER = 'promoter',
	CREDIT_NOTE = 'credit-note'
}
