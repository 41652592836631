import { Drawer, notification, Button, List } from 'antd';
import { FormLayout } from '../FormLayout';
import { apiHms } from 'shared/api';
import { useEffect, useState } from 'react';
import SelectRetailerDropdown from '../SelectRetailerDropdown/SelectRetailerDropdown';
import { HMSUSERTYPE } from 'app/AppAdmin/D2R/utils';

export interface IPromoterInfoSlider {
	isPromoterDrawerVisible: boolean;
	promoterData: any;
	setIsPromoterDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setReloadPromoterList: React.Dispatch<React.SetStateAction<number>>;
	promoterId: string | number;
	retailerList: any;
	setRetailersList: any;
}

export const PromoterInfoSlider = ({
	isPromoterDrawerVisible,
	promoterData,
	setIsPromoterDrawerVisible,
	setReloadPromoterList,
	promoterId,
	retailerList,
	setRetailersList
}: IPromoterInfoSlider) => {
	const [retailers, setRetailers] = useState([]);
	const [retailertoAdd, setRetailertoAdd] = useState<any>([]);

	useEffect(() => {
		setRetailers(promoterData[0]?.retailers);
	}, [promoterData]);

	const handleCloseDrawer = () => setIsPromoterDrawerVisible(false);

	const updatePromoterData = async (values: any) => {
		try {
			const { data } = await apiHms.patch(`/admin/promoter/update`, {
				...values,
				userId: promoterId,
				usertype: HMSUSERTYPE.PROMOTER
			});
			if (data?.status) {
				notification.success({
					message: 'Success',
					description: data?.message,
					placement: 'topRight'
				});
			}
		} catch (error: any) {
			const msg: string =
				error?.response?.data?.responseMessage || error?.response?.data?.message || error?.message || 'Something went wrong';
			notification.error({
				message: 'Failed',
				description: msg,
				placement: 'topRight'
			});
		} finally {
			setReloadPromoterList((prev: number) => prev + 1);
		}
	};

	const onPromoterEditFormSubmit = async (values: any) => {
		await updatePromoterData(values);
	};

	const addRetailer = () => {
		if (retailertoAdd.length === 0) {
			notification.error({
				message: 'Error',
				description: 'Please select a retailer',
				placement: 'topRight'
			});
			return;
		}
		const isAlreadyPresent = retailers.some((item: any) => retailertoAdd.includes(item.id));

		if (isAlreadyPresent) {
			notification.error({
				message: 'Error',
				description: 'Retailer already mapped to this promoter',
				placement: 'topRight'
			});
			return;
		}

		const values = {
			name: promoterData[0].fullname,
			isActive: promoterData[0].isActive,
			phone: promoterData[0].phone,
			retailers: retailertoAdd
		};
		updatePromoterData(values);
		setRetailertoAdd([]);
	};

	const removeRetailer = async (retailer: any) => {
		const values = {
			name: promoterData[0].fullname,
			isActive: promoterData[0].isActive,
			phone: promoterData[0].phone,
			retailers: [retailer.id]
		};
		updatePromoterData(values);
		setRetailers((prev) => prev.filter((r: any) => r.id !== retailer.id));
	};

	const handleOnChange = (items: any) => {
		setRetailertoAdd(items);
	};

	return (
		<Drawer visible={isPromoterDrawerVisible} onClose={handleCloseDrawer}>
			{promoterData ? (
				<div>
					<FormLayout
						formTitle={'Edit Promoter Details'}
						onSave={(values: any) => onPromoterEditFormSubmit(values)}
						onClose={() => setIsPromoterDrawerVisible(false)}
						formFields={[
							{
								name: 'name',
								id: 'name',
								label: 'Name',
								required: true,
								placeholder: 'Enter name'
							},
							{
								name: 'phone',
								id: 'phone',
								label: 'Phone',
								required: true,
								placeholder: 'Enter Phone'
							},
							{
								name: 'isActive',
								id: 'isActive',
								label: 'Active(0/1)',
								placeholder: 'Enter Status',
								fieldType: 'select',
								required: true,
								options: [
									{
										label: 'Inactive',
										value: false
									},
									{
										label: 'Active',
										value: true
									}
								]
							}
						]}
						initialValues={{
							name: promoterData[0]?.fullname,
							phone: promoterData[0]?.phone,
							status: promoterData[0]?.isActive
						}}
					/>

					{/* Add Retailer Management Section */}
					<div style={{ marginTop: '20px' }}>
						<h3>Manage Retailers</h3>
						<List
							size="small"
							bordered
							dataSource={retailers}
							renderItem={(retailer: any) => (
								<List.Item
									actions={[
										<Button key={retailer.phone} type="link" danger onClick={() => removeRetailer(retailer)}>
											Remove
										</Button>
									]}
								>
									{retailer?.firstName + ' ' + retailer?.lastName + ' ' + retailer?.phone}
								</List.Item>
							)}
						/>
						<div style={{ display: 'flex', marginTop: '16px' }}>
							<SelectRetailerDropdown
								retailerList={retailerList}
								width={260}
								setRetailers={setRetailersList}
								onChange={handleOnChange}
								value={retailertoAdd}
							/>
							<Button type="primary" onClick={addRetailer} style={{ marginLeft: '16px' }}>
								Add
							</Button>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
		</Drawer>
	);
};
