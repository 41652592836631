import { AutoComplete, Select } from 'antd';
import { CODRemittanceStatus } from '../../../../constants';
import { getCompanies } from '../../../../shared/api/lightning';
import { DebouncedCompactSearch } from '../../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import errorHandler from '../../../../shared/utils/errorHandler';
import debounce from '../../../../utils/debounce';
import { searchOptions } from './constants';
import styles from './index.module.scss';
import { IFilter } from './types';
import { ITopFilterUtil } from '../../commonTypes';
import { commonTopFilterRow } from '../../../../utils/filters';

export function topFilterUtil({ filter, setFilter, setPageConfigDefault, companies, setCompanies }: ITopFilterUtil<IFilter>): any {
	async function getAllCompanies(searchValue: string) {
		try {
			const { data }: { data: { status: boolean; companies: { entityId: string; name: string }[] } } = await getCompanies(
				searchValue
			);
			if (data.status) {
				setCompanies(
					data.companies.map((company) => ({
						key: company.entityId,
						value: company.entityId,
						label: company.name,
						...company
					}))
				);
			} else throw new Error(`Couldn't get companies`);
		} catch (error) {
			errorHandler(error);
		}
	}

	const setDate = (startDate: string, endDate: string) => {
		setPageConfigDefault();
		setFilter((prevFilter: IFilter) => ({ ...prevFilter, start: startDate, end: endDate }));
	};

	const handleRemittanceStatusChange = (remittanceStatus: string) => {
		setPageConfigDefault();
		setFilter((prevFilter: IFilter) => ({ ...prevFilter, remittanceStatus }));
	};

	const handleCompanyChange = (company?: string) => {
		setPageConfigDefault();

		// When clicked on clear button
		if (!company) {
			setFilter((prevFilter: IFilter) => ({ ...prevFilter, companyID: undefined, companyName: undefined }));
		}
		// When a company is selected
		else {
			const { id, name }: { id: string; name: string } = JSON.parse(company);
			setFilter((prevFilter: IFilter) => ({ ...prevFilter, companyID: id, companyName: name }));
		}
	};

	const debouncedCompanySearchChange = debounce((company: string) => {
		if (company.trim()) {
			getAllCompanies(company);
		} else {
			setCompanies([]);
		}
	}, 500);
	const topFilterRow = commonTopFilterRow(filter.start, filter.end);

	return {
		row1: [
			{
				...topFilterRow,
				onChange: (dates: any, dateStrings: [string, string]) => setDate(...dateStrings)
			}
		],
		row1children: [
			<div className={styles.topRow}>
				<DebouncedCompactSearch
					defaultSearchOptionType={filter.searchOptionType}
					searchOptions={searchOptions}
					setFilter={setFilter}
					setDefault={setPageConfigDefault}
					selectStyle={{ minWidth: '100px' }}
				>
					<span>Search By:</span>
				</DebouncedCompactSearch>
			</div>,
			<div className={styles.remittanceStatusFilter}>
				<span>Remittance Status:</span>
				<Select
					mode="multiple"
					maxTagCount="responsive"
					placeholder="Select Remittance Status"
					style={{ width: '100%' }}
					value={filter.remittanceStatus}
					allowClear={true}
					onChange={handleRemittanceStatusChange}
				>
					{Object.keys(CODRemittanceStatus).map((status) => {
						return <Select.Option key={status}>{status}</Select.Option>;
					})}
				</Select>
			</div>,
			<div>
				<span>Companies:</span>
				<div>
					<AutoComplete
						placeholder="Search for a company"
						onSearch={debouncedCompanySearchChange}
						onClear={() => handleCompanyChange()}
						onSelect={handleCompanyChange}
						style={{ width: '200px' }}
						allowClear
						value={filter.companyName}
						notFoundContent="No companies found"
					>
						{companies?.map(({ key, value, label }) => {
							return (
								<AutoComplete.Option key={key} value={JSON.stringify({ id: value, name: label })}>
									{label}
								</AutoComplete.Option>
							);
						})}
					</AutoComplete>
				</div>
			</div>
		],
		row2: []
	};
}
