import { BeatsD2R } from './Beats/Index';
import { CreditNoteList } from './CreditNote/CreditNoteList';
import { companyType } from './D2r';
import { DiscountD2r } from './Discount/Index';
import { ExternalRetailers } from './ExternalRetailers';
import Invoices from './Invoices';
import { PromoterD2R } from './Promoter';
import { PromoterStock } from './PromoterStockDetails';
import Recomendation from './RecomendationEngine';
import { Retailers } from './Retailers';
import { SalesmanD2R } from './Salesmans';

export function getD2RSubPageMapper(company: companyType): { [key: string]: JSX.Element } {
	return {
		'1': <Retailers company={company} />,
		'2': <CreditNoteList company={company} />,
		'3': <Invoices company={company} />,
		'4': <SalesmanD2R />,
		'5': <PromoterD2R />,
		'6': <PromoterStock />,
		'7': <DiscountD2r company={company} />,
		'8': <BeatsD2R company={company} />,
		'9': <ExternalRetailers />,
		'10': <Recomendation />
	};
}

export interface ICommonFilterSearch {
	searchOptionType: string;
	searchValue?: string;
}

export enum HMSUSERTYPE {
	SALESMAN = '004',
	PROMOTER = '005'
}
