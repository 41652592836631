import { Col, Modal, Row, Table } from 'antd';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IFilter, topFilterUtil, defaultFilter } from './utils';
import { CustomTablePagination } from '../../../../shared/components/CustomTablePagination';
import errorHandler from '../../../../shared/utils/errorHandler';
import { fetchAllRecords } from 'shared/api/lightning';
import d2rPromoterStockColumns from './columns';
import { defaultPageConfig } from '../../../../constants';
import { IPageConfiguration } from '../../../../app/AppAdmin/commonTypes';
import { envs } from '../../../../shared/utils/env';
import { D2REndpointType } from 'shared/api/misc';

const { hmsBaseUrl } = envs;

export const PromoterStockDetails = () => {
	const [data, setData] = useState<any[]>([]);
	const [currentCount, setCurrentCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<IFilter>(defaultFilter);
	const [pageConfig, setPageConfig] = useState<IPageConfiguration>(defaultPageConfig);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [isCatalogueModalOpen, setIsCatalogueModalOpen] = useState(false);
	const [selectedCatalogues, setSelectedCatalogues] = useState<any[]>([]);

	const columns = d2rPromoterStockColumns(setIsCatalogueModalOpen, setSelectedCatalogues);

	const setPageConfigDefault = useCallback(() => {
		setPageConfig(defaultPageConfig);
	}, []);

	function onCSVDownload() {
		fetchAllRecords(D2REndpointType.PROMOTER, hmsBaseUrl, pageConfig, filter, 1);
	}

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				setPageConfigDefault,
				selectedRowKeys,
				onCSVDownload
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filter, setFilter, setPageConfigDefault, selectedRowKeys]
	);

	async function getPromoterStockDetails(pageConfiguration: any, filters: any) {
		try {
			setLoading(true);
			const response = await fetchAllRecords('promoter', hmsBaseUrl, pageConfiguration, filters);
			if (response?.data.status) {
				setData(response.data.promoterTransactions);
				setCurrentCount(response.data.totalRecords);
			} else {
				throw new Error(response?.data.message || 'Could not get promoter stock data');
			}
		} catch (error: unknown) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		setFilter((prevFilter: IFilter) => ({ ...prevFilter }));
	}, []);

	useEffect(() => {
		getPromoterStockDetails(pageConfig, filter);
	}, [filter, pageConfig]);

	return (
		<Row className="h-100 p-0">
			<Modal
				title="Catalogue Details"
				visible={isCatalogueModalOpen}
				onCancel={() => setIsCatalogueModalOpen(false)}
				footer={null}
				width={800}
			>
				<Table dataSource={selectedCatalogues} pagination={false} rowKey="sku">
					<Table.Column title="Title" dataIndex="title" key="title" />
					<Table.Column title="SKU" dataIndex="sku" key="sku" />
					<Table.Column title="Stock Count" dataIndex="stockCount" key="stockCount" />
				</Table>
			</Modal>
			<Col span={24} className="main">
				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columns}
							data={data}
							onChangePage={(current: any, pageSize: any) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							current={Number(pageConfig.current)}
							total={currentCount}
							loading={loading}
							shouldRowSelection={true}
							selectedItem={undefined}
							catalog={undefined}
							selectedRowKeys={undefined}
							setSelectedRowKeys={setSelectedRowKeys}
							setSelectedItem={undefined}
							pageSize={Number(pageConfig.pageSize)}
							sortField={pageConfig.sortField}
							sortOrder={pageConfig.sortField}
						/>
					</div>
				</div>
			</Col>
		</Row>
	);
};
