import { Col, Row } from 'antd';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TopFilterComponent } from '../AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../CustomTablePagination';
import errorHandler from '../../utils/errorHandler';
import useColumns from './columns';
import { defaultFilter } from './constants';
import { ITaskCenterFilter, IPageConfiguration } from './types';
import { topFilterUtil } from './utils';
import { getTaskCenterList } from '../../api/taskCenter';
import { SocketContext } from '../../contexts/SocketContext';
import { ISocketConstant } from '../OrderInfoSlider/components/ChatUi/constant';
import { AuthContext } from '../../contexts/Auth';
import { defaultPageConfig } from '../../../constants';

export interface ITaskCenter {
	isAdmin: boolean | string;
	companyId: string | undefined;
}
export function TaskCenterAdmin({ isAdmin = false, companyId = undefined }: ITaskCenter) {
	const [data, setData] = useState<any[]>([]);
	const [updatedTaskIdStatus, setUpdatedTaskIdStatus] = useState<
		| {
				taskId: string;
				status: string;
				message: string;
				downloadLink: string;
		  }
		| undefined
	>(undefined);
	const [currentCount, setCurrentCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<ITaskCenterFilter>(defaultFilter);
	const [pageConfig, setPageConfig] = useState<IPageConfiguration>(defaultPageConfig);

	const socketIo = useContext(SocketContext);
	const { authState } = useContext(AuthContext); // authActions
	const [progressMap, setProgressMap] = useState<any>({});
	const columns = useColumns(progressMap);

	useEffect(() => {
		/**
		 * listening to socket event when socket instance exists
		 */
		if (socketIo) {
			socketIo.on(ISocketConstant.TASK_STATUS_CHANGE, taskStatusChangeHandler);
			socketIo.on(ISocketConstant.TASK_CENTER_PROGRESS_UPDATE, taskStatusChangeHandlerProgressPercent);
			return () => {
				socketIo.off(ISocketConstant.TASK_STATUS_CHANGE, taskStatusChangeHandler);
				socketIo.off(ISocketConstant.TASK_CENTER_PROGRESS_UPDATE, taskStatusChangeHandlerProgressPercent);
			};
		}
	}, [socketIo]);

	const taskStatusChangeHandlerProgressPercent = async (payloadData: any) => {
		//	const profileData = await authActions.obtainProfileData().finally(() => setLoading(false));
		if (payloadData && payloadData.companyId === authState.profile.id && payloadData?.progressPercent) {
			setProgressMap((prevMap: any) => ({
				...prevMap,
				taskId: payloadData.taskId,
				[payloadData.taskId]: payloadData?.progressPercent,
				[`message-${payloadData.taskId}`]: payloadData?.message
			}));
		}
	};
	const taskStatusChangeHandler = async (payloadData: any) => {
		if (
			payloadData &&
			//profileData.responseBody &&
			// payloadData.companyId === profileData.responseBody.id
			payloadData.companyId === authState.profile.id
		) {
			setUpdatedTaskIdStatus({
				taskId: payloadData.taskId,
				status: payloadData.status,
				message: payloadData.message,
				downloadLink: payloadData.downloadLink || ''
			});
		}
	};

	const setPageConfigDefault = useCallback(() => {
		setPageConfig(defaultPageConfig);
	}, []);

	const topFilterConfig = useMemo(
		() => topFilterUtil({ filter, setFilter, setPageConfigDefault }),
		[filter, setFilter, setPageConfigDefault]
	);

	const getAllTasks = async ({ pageConfiguration, filters, isAdmin, companyId }: any) => {
		try {
			const response = await getTaskCenterList({ pageConfiguration, filters, isAdmin, companyId });
			if (response?.data.status) {
				setData(response.data.data);
				setCurrentCount(response.data.count);
			} else {
				throw new Error(response?.data.message || 'Could not get task list');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (updatedTaskIdStatus?.taskId) {
			setLoading(true);
			const updatedData = data.map((dataItem) => {
				return dataItem.id === updatedTaskIdStatus?.taskId
					? {
							...dataItem,
							status: updatedTaskIdStatus?.status,
							//	description: updatedTaskIdStatus?.status === taskStatus.FAILED ? dataItem.description : '-',
							downloadLink: updatedTaskIdStatus?.downloadLink,
							endedAt: new Date()
					  }
					: dataItem;
			});
			setData(updatedData);
			setLoading(false);
		}
	}, [updatedTaskIdStatus]);

	useEffect(() => {
		getAllTasks({ pageConfiguration: pageConfig, filters: filter, isAdmin, companyId });
	}, [filter, pageConfig, progressMap.taskId]);

	return (
		<div style={{ height: window.innerHeight - 40 }}>
			<Row className="h-100 p-0">
				<Col span={24} className="main">
					<TopFilterComponent {...topFilterConfig} />
					<div className="innerDiv">
						<div className="div w-100 h-100">
							<CustomTablePagination
								columns={columns}
								data={data.map((item) => ({
									...item,
									taskId: item.id
								}))}
								onChangePage={(current: any, pageSize: any) => setPageConfig((v: any) => ({ ...v, current, pageSize }))}
								current={Number(pageConfig.current)}
								total={currentCount}
								loading={loading}
								shouldRowSelection={false}
								selectedItem={undefined}
								catalog={undefined}
								selectedRowKeys={undefined}
								setSelectedRowKeys={undefined}
								setSelectedItem={undefined}
								pageSize={Number(pageConfig.pageSize)}
								sortField={pageConfig.sortField}
								sortOrder={pageConfig.sortField}
							/>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
}
