import React, { useEffect, useState } from 'react';
import { Button, Tag, Row, Tooltip } from 'antd';
import { images as channelImages } from 'shared/utils/channel';
import { orderStatus, orderStatusColor } from 'shared/utils/orderStatus';
import { isSortField } from 'shared/utils/table';
import styles from './index.module.scss';
import { getTrackingUrl } from 'utils/delivery/getTrackingUrl';
import { FulfillmentProviders, ORDER_STATUS } from '../../../constants/index';
import RTOModal from '../RTOApproval/components/RTOModal';
import { obtainProfileData } from 'shared/api/profile';
import { commonColumns } from 'shared/utils/columns';

export interface IColumns {
	clickOrderId?: (id: any) => void;
	sortField?: string;
	sortOrder?: string;
	clickProductId?: (id: any) => void;
	isAdmin?: boolean;
	setCustomerId?: React.Dispatch<any>;
}

const getDeliveryPartnerColor = (deliveryPartner: string) => {
	deliveryPartner = deliveryPartner?.toLowerCase();
	let color;

	switch (deliveryPartner) {
		case 'dtdc':
			color = 'orange';
			break;

		case 'xpressbees':
			color = 'cyan';
			break;

		default:
			color = 'gold';
			break;
	}
	return color;
};

export const useColumns = ({
	clickOrderId,
	sortField,
	sortOrder,
	clickProductId = () => {
		return;
	},
	isAdmin = false,
	setCustomerId
}: IColumns) => {
	const [companyProfileData, setCompanyProfileData] = useState();

	useEffect(() => {
		const fetchProfileData = async () => {
			const data = await obtainProfileData();
			return data;
		};

		fetchProfileData()
			.then((data) => {
				setCompanyProfileData(data.data.responseBody);
			})
			.catch((error) => {
				console.log('!!!!!!!Error!!!!!', error);
			});
	}, []);
	const getModalApprove = (status: any, order: any) => {
		if (
			status === ORDER_STATUS.RTO_DELIVERED ||
			status === ORDER_STATUS.DTO_DELIVERED ||
			(order.companyId === '4f73adc3-463b-4fa5-9b5b-fa12afc438f9' && status === ORDER_STATUS.READY_TO_SHIP)
		) {
			return (
				<RTOModal
					orderData={order}
					button={
						status === ORDER_STATUS.RTO_DELIVERED || order.companyId === '4f73adc3-463b-4fa5-9b5b-fa12afc438f9'
							? 'Approve RTO'
							: 'Approve DTO'
					}
					companyProfileData={companyProfileData}
				/>
			);
		}
	};

	const getModalDetails = (status: any, order: any) => {
		if (status === ORDER_STATUS.RTO_PROCESSED || status === ORDER_STATUS.DTO_PROCESSED) {
			return (
				<RTOModal
					orderData={order}
					button={status === ORDER_STATUS.RTO_PROCESSED ? 'RTO Details' : 'DTO Details'}
					companyProfileData={companyProfileData}
				/>
			);
		}
	};
	const handleOrderClickEvent = (e: any, order: any) => {
		e.preventDefault();
		e.stopPropagation();
		if (clickOrderId) {
			clickOrderId(order?.id);
		}
	};
	const handleStatusClickEvent = (order: any) => {
		if (setCustomerId) setCustomerId(order.customerRequest?.[0]?.customerId ?? '');
	};
	return [
		commonColumns.createAt(sortField, sortOrder),
		{
			title: isAdmin ? 'RTS At' : '',
			dataIndex: 'rtsAt',
			render: isAdmin ? (text: any) => <div className="text-center">{text ? new Date(text).toLocaleString() : 'NA'}</div> : '',
			width: isAdmin ? 100 : 0,
			sorter: true,
			sortOrder: isSortField(sortField, sortOrder, 'rtsAt')
		},
		{
			title: 'Order ',
			dataIndex: 'shopOrderId',
			render: (v: any, order: any) => (
				<>
					<Row justify="center">
						<Button type="link" onClick={(e) => handleOrderClickEvent(e, order)} className={styles.wFull}>
							<Tooltip placement="top" title={order.shopOrderId || order.externalOrderId || order.id}>
								<span className={styles.ellipsis}>{order.shopOrderId || order.externalOrderId || order.id}</span>
							</Tooltip>
						</Button>
					</Row>
					<Row justify="center">
						<Tag color="geekblue">{order.paymentMode}</Tag>
					</Row>
					<Row justify="center" className={styles.b2bTag}>
						{order.isb2bOrder ? <Tag color="pink">B2B</Tag> : <></>}
					</Row>
					<Row justify="center">
						{order.isD2rApproved === 0 && order.isD2ROrder ? (
							<div className="text-center">
								<Tag color="pink">High Margin</Tag>
							</div>
						) : (
							''
						)}
					</Row>
				</>
			),
			width: 150,
			sorter: true,
			sortOrder: isSortField(sortField, sortOrder, 'shopOrderId')
		},
		commonColumns.staticRenderFieldWithSortOption('Channel', 'shopType', sortField, sortOrder, true, channelImages, 100),
		{
			title: 'Product Info',
			dataIndex: 'items',
			render: (obj: any) => (
				<div>
					{obj?.length
						? obj.map((v: any, order: any) => (
								<div className="w-100 p-relative float-left" key={v.id}>
									<div className="text-left">{v.title}</div>
									<div>
										<Button
											type="link"
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												clickProductId(v.catalogueId);
											}}
											className="float-left"
										>
											{v.sku}
										</Button>
										<span className="float-right">{v.quantity}</span>
									</div>
								</div>
						  ))
						: '-'}
				</div>
			),
			width: 150
		},
		{
			title: 'Pickup Address',
			key: 'pickupAddress',
			render: (order: any, status: any) => (
				<div className={styles.pickupAddress}>
					{
						<>
							<div
								style={{ margin: '0 auto', overflowWrap: 'anywhere' }}
							>{`${order.pickupAddress?.firstName}, ${order.pickupAddress?.city}, ${order.pickupAddress?.pincode}`}</div>
							<div className="w-100">
								{order.deliveryAwb && status != ORDER_STATUS.RTS_FAILED && (
									<Button
										type="link"
										onClick={() => {
											window.open(
												getTrackingUrl(FulfillmentProviders.WHEREHOUSE_LIGHTNING, order.deliveryAwb),
												'_blank'
											);
										}}
									>
										AWB: {order.deliveryAwb}
									</Button>
								)}
								{order.deliveryPartnerId &&
									!(order.deliveryPartnerId === FulfillmentProviders.SHIPERFECTO && order.whCreds) && (
										<Tag className={`${styles.tag} mt-1`} color={getDeliveryPartnerColor(order.deliveryPartnerId)}>
											{order.deliveryPartnerId}
										</Tag>
									)}
								<br />
								{order.shipper && (
									<Tag className={`${styles.tag} mt-1`} color={getDeliveryPartnerColor(order.deliveryPartnerId)}>
										{order.shipper}
									</Tag>
								)}
								{order.remarks && (
									<Tag className={`${styles.tag} mt-1`} color={getDeliveryPartnerColor(order.remarks)}>
										{order.remarks}
									</Tag>
								)}
							</div>
						</>
					}
				</div>
			),
			width: 150
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (status: any, order: any) => (
				<>
					{order?.customerRequest && Boolean(order?.customerRequest?.length) && (
						<Button onClick={() => handleStatusClickEvent(order)} type="link">
							NDR info
						</Button>
					)}
					{status ? (
						<div
							style={{
								color: orderStatusColor[status]
							}}
							className="text-center"
						>
							{orderStatus[status] || status}
							{getModalDetails(status, order)}
							{getModalApprove(status, order)}
						</div>
					) : (
						<div className="text-center">-</div>
					)}
				</>
			),
			width: 100,
			sorter: true,
			sortOrder: isSortField(sortField, sortOrder, 'status')
		}
	];
};
