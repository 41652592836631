import { Select } from 'antd';
import React, { useEffect } from 'react';
import { HighlightedText } from '../HighlightedText';
import { getStoreAddress } from 'app/AppSeller/Orders/ManualOrder/utils';

interface SelectRetailerDropdownProps {
	handleSelect?: any;
	retailerList: [];
	width?: string | number;
	setRetailers: (retailers: any[]) => void;
	filter?: string;
	value?: string[];
	onChange?: (values: string[]) => void;
}

const SelectRetailerDropdown = ({
	handleSelect,
	retailerList,
	width,
	setRetailers,
	filter = 'storeName',
	value,
	onChange
}: SelectRetailerDropdownProps) => {
	const [searchTerm, setSearchTerm] = React.useState('');

	const handleFilterOption = (input: string, option: any) => {
		return option?.children.props.text?.toLowerCase().includes(input.toLowerCase());
	};

	const handleSearchByQuery = async (input: string) => {
		setSearchTerm(input);

		// Check if the input length is greate than 2 and all characters are number
		const containsNumber = input.length > 2 && /^\d+$/.test(input);

		if (containsNumber) {
			const {
				data: { storeAddressData }
			} = await getStoreAddress('', input, true, 'phone');
			setRetailers(storeAddressData);
			return;
		}
		const {
			data: { storeAddressData }
		} = await getStoreAddress('', input, true, filter);
		setRetailers(storeAddressData);
	};

	useEffect(() => {
		handleSearchByQuery(searchTerm);
	}, [searchTerm, filter]);

	return (
		<div>
			<Select
				style={{ width: width ?? '100%' }}
				placeholder="Select Store Address"
				optionFilterProp="children"
				onSelect={(value: any, option: any) => handleSelect(value)}
				onSearch={setSearchTerm}
				onChange={onChange}
				filterOption={handleFilterOption}
				mode="multiple"
				showSearch
				value={value}
			>
				{retailerList.map(({ id, firstName, lastName, isActive, phone, GSTIN }: any) => {
					const fullAddress = `${firstName} ${lastName}, ${phone}, ${GSTIN ?? ''}`;
					return (
						isActive && (
							<Select.Option value={id} key={id}>
								<HighlightedText text={fullAddress} highlight={searchTerm} />
							</Select.Option>
						)
					);
				})}
			</Select>
		</div>
	);
};

export default SelectRetailerDropdown;
