import { Modal, notification, Select, Button } from 'antd';
import { useState } from 'react';
import { baseApi } from '../../../../api';
import errorHandler from '../../../../utils/errorHandler';

const { Option } = Select;

interface IRegenerateCreditNoteModal {
	order: any;
	setIsRegenerateCreditNoteModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setReloadOrder: React.Dispatch<React.SetStateAction<number>>;
}

export const RegenerateCreditNoteModal = (props: IRegenerateCreditNoteModal) => {
	const { order, setIsRegenerateCreditNoteModalVisible, setReloadOrder } = props;
	console.log(order.creditDebitNotes, 'orderdetail');

	const [loading, setLoading] = useState(false);
	const [selectedCreditNoteId, setSelectedCreditNoteId] = useState<string | null>(null);

	const handleOnFinish = async () => {
		if (!selectedCreditNoteId) {
			notification.error({
				message: 'Please select a credit note to regenerate'
			});
			return;
		}

		try {
			setLoading(true);
			const { data } = await baseApi.get(`/wms/inventory/regenerate-credit-note/${order?.id}/${selectedCreditNoteId}`);

			if (data?.status) {
				notification.success({
					message: data?.message
				});
				setIsRegenerateCreditNoteModalVisible(false);
				setReloadOrder((e) => e + 1);
			}
		} catch (err) {
			errorHandler(err);
		} finally {
			setLoading(false);
		}
	};

	const handleCreditNoteChange = (value: string) => {
		setSelectedCreditNoteId(value);
	};

	// Assuming creditDebitNotes is an array of objects with id and some identifier like creditNoteId or index
	const creditNotes = order.creditDebitNotes || [];

	return (
		<Modal
			title="Regenerate Credit Notes"
			visible={true}
			onCancel={() => setIsRegenerateCreditNoteModalVisible(false)}
			footer={[
				<Button key="cancel" onClick={() => setIsRegenerateCreditNoteModalVisible(false)}>
					Cancel
				</Button>,
				<Button key="regenerate" type="primary" loading={loading} onClick={handleOnFinish} disabled={!selectedCreditNoteId}>
					Regenerate
				</Button>
			]}
			width={'50vw'}
		>
			<div style={{ padding: '16px' }}>
				<label>Select Credit Note to Regenerate:</label>
				<Select
					style={{ width: '100%', marginTop: '8px' }}
					placeholder="Select a credit note"
					onChange={handleCreditNoteChange}
					value={selectedCreditNoteId}
					loading={loading}
				>
					{creditNotes.map((note: any, index: any) => (
						<Option key={note.id} value={note.id}>
							Credit Note :{index + 1}
						</Option>
					))}
				</Select>
			</div>
		</Modal>
	);
};
